// import { XENEXISData } from "./XENEXISData";
// import XENEXISComponent from "./XENEXISComponent";
import "./XENEXIS.css";
import Fade from "react-reveal/Fade";

const XENEXIS = () => {
  return (
    <div>
      <div className="XENEXIS">
        <div className="X-t-sec">
          <Fade bottom>
            <h1 className="t01-1">
              <span>03</span>XENEXIS MEMBERSHIP NFTs
            </h1>
          </Fade>
          <div className="M-t">
            <p className="M-t-3">
              <Fade bottom>
                <h6 className="M-t-">3.0</h6>
              </Fade>
              <Fade bottom>
                <p className="M-t-1-1">
                There are eight types of NFTs, each of which has its own unique value that is all unified by one ultimate vision.
                  <br />
                </p>
              </Fade>
            </p>
          </div>
        </div>
        <div className="XENEXIS-gif">
          <Fade bottom>
            <div className="XENEXIS-gif-box">
              <a className="ATTRIBUTE" href="/">
                DO
              </a>
            </div>
          </Fade>
          <Fade top>
            <div className="XENEXIS-gif-box-2">
              <a className="ATTRIBUTE" href="/">
                RE
              </a>
            </div>
          </Fade>
          <Fade bottom>
            <div className="XENEXIS-gif-box-3">
              <a className="ATTRIBUTE" href="/">
                MI
              </a>
            </div>
          </Fade>
          <Fade top>
            <div className="XENEXIS-gif-box-4">
              <a className="ATTRIBUTE" href="/">
                FA
              </a>
            </div>
          </Fade>
          <Fade bottom>
            <div className="XENEXIS-gif-box-5">
              <a className="ATTRIBUTE" href="/">
                SOL
              </a>
            </div>
          </Fade>
          <Fade top>
            <div className="XENEXIS-gif-box-6">
              <a className="ATTRIBUTE" href="/">
                RA
              </a>
            </div>
          </Fade>
          <Fade bottom>
            <div className="XENEXIS-gif-box-7">
              <a className="ATTRIBUTE" href="/">
                SI
              </a>
            </div>
          </Fade>
        </div>
        {/* <div className="M-t-b">
          <p className="M-t-4">
            <Fade bottom>
              <h6 className="M-t-">3.1</h6>
            </Fade>
            <Fade bottom>
              <p className="M-t-1-1"></p>
            </Fade>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default XENEXIS;
