import React from "react";
import "./Banner.css";
import Pulse from "react-reveal/Pulse";

// import Particles from "react-particles-js";
// import { particlesParams } from "./Particle";
// import VideoPlayer from "react-background-video-player";
// import Logo from "../Header/XENEXIS.svg";
// import Main from "./Main.mp4";

import StarfieldAnimation from "react-starfield-animation";

const Banner = (props) => {
  return (
    <div className="Banner">
      {/* <Particles params={particlesParams} /> */}
      <div className="B-t-t">
        <StarfieldAnimation
          className="particles"
          style={{
            alphaFactor: 3,
            lineWidth: "100px",
            depth: 400,
            position: "absolute",
            transform: "translate(0%, -30%)",
            width: "100%",
            height: "90%",
          }}
        />
        <p className="B-t-2">
          <Pulse>
            <p className="B-t-1-1">
              Our mission is to bring revolutionary changes to below industrial
              sectors:
              <br />
              Food technology, Clean energy, Blockchain, Social impact,
              Logistics,
              <br />
              Infrastructure, Robotics, Education, Well-being, Travel, NFTs,
              Music, Finance,
              <br />
              3D printing, Communication, Information, Architecture, Game,
              Transportation,
              <br />
              Living, Fashion, Human enhancement, Metaverse, Medicine, Real
              estate, Sports,
              <br />
              Patent, OTT
            </p>
          </Pulse>
        </p>
      </div>
    </div>
  );
};

export default Banner;
