import "./Maestro.css";
import Maestroimg from "./dyamond_motion8.gif";
import Bubble from "./Bubble.svg";
import Fade from "react-reveal/Fade";

const Maestro = () => {
  return (
    <div>
      <div className="Bubble-img">
        <Fade bottom>
          <img className="Bubblepng" src={Bubble} alt="png" />
        </Fade>
        <div className="M-t-sec01">
          <Fade bottom>
            <h1 className="mt01">
              <span>01</span>THE MANIFESTO
            </h1>
          </Fade>
          <div className="M-t-t">
            <p className="M-t-2">
              <Fade bottom>
                <h6 className="M-t-">1.0</h6>
              </Fade>
              <Fade bottom>
                <p className="M-t-1-1">
                  Our vision for the NFT culture and preferred actions for
                  <br />
                  change is different. Nonetheless, XENEXIS holders agree
                  <br />
                  on several doctrines.
                </p>
              </Fade>
            </p>
            <p className="M-t-2">
              <Fade bottom>
                <h6 className="M-t-">1.1</h6>
              </Fade>
              <Fade bottom>
                <p className="M-t-1-1">
                  Rejection of a centralized system, denial of fake web3
                  <br />
                  companies, freedom of information, and commitment to
                  <br />
                  ignite the new methods of transferring values.
                </p>
              </Fade>
            </p>
          </div>
        </div>
      </div>

      {/* ============================ */}

      <div className="Maestro-img">
        <div className="M-t-sec">
          <Fade bottom>
            <h1 className="t01">
              <span>02 </span>XENEXIS?
            </h1>
          </Fade>
          <div className="M-t">
            <p className="M-t-2">
              <Fade bottom>
                <h6 className="M-t-">2.0</h6>
              </Fade>
              <Fade bottom>
                <p className="M-t-1-2">
                  XENEXIS NFTs exist in 8 different types: Do, Re,
                  <br />
                  Mi, Fa, Sol, La, Si, and Maestro. There are 7,888
                  <br />
                  supplies in total.
                </p>
              </Fade>
            </p>
            <p className="M-t-2">
              <Fade bottom>
                <h6 className="M-t-">2.1</h6>
              </Fade>
              <Fade bottom>
                <p className="M-t-1-2">
                  Of the 7,888 NFTs, there are only 111 Maestro,
                  <br />
                  and the rest are 1,111 per NFT. Each membership
                  <br />
                  NFT has its unique traits assigned. Hence, the
                  <br />
                  benefits and opportunities vary according to its
                  <br />
                  inherent theme.
                </p>
              </Fade>
            </p>
          </div>
        </div>

        <div className="M-img-sec-1">
          <Fade bottom>
            <img className="Maestropng" src={Maestroimg} alt="png" />
          </Fade>
        </div>
      </div>

      {/* ============================ */}
    </div>
  );
};

export default Maestro;
