import Particles from "react-tsparticles";
import "../../App.css";

const ParticlesBackground = () => {
  return (
    <div className="App">
        <Particles
        options={{
          detectRetina: false,
          fpsLimit: 30,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push"
              },
              onDiv: {
                elementId: "repulse-div",
                enable: false,
                mode: "repulse"
              },
              onHover: {
                enable: true,
                mode: "bubble",
                parallax: {
                  enable: false,
                  force: 2,
                  smooth: 10
                }
              },
              resize: true
            },
            modes: {
              bubble: {
                distance: 40,
                duration: 2,
                opacity: 8,
                size: 3
              },
              connect: {
                distance: 80,
                lineLinked: {
                  opacity: 0.5
                },
                radius: 60
              },
              grab: {
                distance: 400,
                lineLinked: {
                  opacity: 1
                }
              },
              push: {
                quantity: 4
              },
              remove: {
                quantity: 3
              },
              repulse: {
                distance: 200,
                duration: 0.4
              },
              slow: {
                active: false,
                radius: 0,
                factor: 1
              }
            }
          },
          particles: {
            color: {
              value: "#ffffff"
            },
            lineLinked: {
              blink: false,
              color: "#000000",
              consent: false,
              distance: 30,
              enable: false,
              opacity: 0.3,
              width: 1
            },
            move: {
              attract: {
                enable: false,
                rotate: {
                  x: 600,
                  y: 1200
                }
              },
              bounce: true,
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: true,
              speed: 0.7,
              straight: false
            },
            number: {
              density: {
                enable: false,
                area: 3000
              },
              limit: 0,
              value: 300 //Start Num
            },
            opacity: {
              value: 0.4
            },
            shape: {
              type: "circle"
            },
            size: {
              value: 0.9
            }
          },
        //   polygon: {
        //     draw: {
        //       enable: true,
        //       lineColor: "rgba(255,255,255,1)",
        //       lineWidth: 1
        //     },
        //     enable: true,
        //     move: {
        //       radius: 10
        //     },
        //     position: {
        //       x: 50,
        //       y: 50
        //     },
        //     inlineArrangement: "equidistant",
        //     scale: 2,
        //     type: "inside",
        //     data:
        //       '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="200" width="150"><path d="M 75,0 0,200 h 150 z" /></svg>'
        //   },
          background: {
            color: "none",
            image: "",
            position: "50% 50%",
            repeat: "no-repeat",
            size: "cover"
          }
        }}
      />
    </div>
  );
}

export default ParticlesBackground;
