import "./Header.css";
import logo from "./XENEXIS.png";

const Header = () => {
  return (
    <div className="Header">
      <div className="Header-sec">
        <div className="logo-sec">
          <a href="/">
            <img className="logo" src={logo} alt="png" />
          </a>
        </div>
        <div className="Menu">
          <a className="Mint" href="https://mint.xenexis.io">
            MINT NFT
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
