import "./Light.css";
import Eclipse from "./Eclipse.png";
import land from "./image.svg";
import Fade from "react-reveal/Fade";
// import asset3 from "./Assets-3.png";
// import { Scrambler } from "react-text-scrambler";
// import Typical from "react-typical";
// import Typist from "react-typist";
// import { SplitColorChannelText } from "react-text-fun";

const Light = () => {
  //   const characters = "abcdefghijklnmopqrstuvwxyz";
  //   const strings = [","];

  return (
    <div className="Light-img">
      <div className="L-t-sec">
        <Fade bottom>
          <h1 className="L-t-h1">
            WE ARE
            <br />
            REVOLUTIONARIES
          </h1>
        </Fade>
        <div className="L-t">
          <p className="L-t-2">
            <Fade bottom>
              <h6 className="L-t-">0.0</h6>
            </Fade>
            <Fade bottom>
              <p className="L-t-1-1">
              We are frontrunners in the cryptocurrency and NFT industry. We have
            <br />an extremely selective curation and due diligence process for
            <br />partnerhips and collaboration to provide only the game-changers.
              </p>
            </Fade>
          </p>
          <p className="L-t-2">
            <Fade bottom>
              <h6 className="L-t-">0.1</h6>
            </Fade>
            <Fade bottom>
              <p className="L-t-1-1">
              Each membership grants priority access to all projects that XENEXIS
              <br />has chosen and allows the private mint.
              </p>
            </Fade>
          </p>
        </div>
      </div>
      <Fade bottom>
        <div className="L-img-sec-1">
          <img className="Eclipsepng" src={Eclipse} alt="png" />
          <img className="landpng" src={land} alt="png" />
        </div>
      </Fade>
    </div>
  );
};

export default Light;
