import "./App.css";
import "./Fonts/Montserrat/Fonts.css";
import ParticlesBackground from "./Components/Particles/ParticlesBackground";

import bgif from "./Maestro.gif";
import Header from "./Components/Header/Header";
import Maestro from "./Components/Maestro/Maestro";
import XENEXIS from "./Components/XENEXIS/XENEXIS";
import Banner from "./Components/Banner/Banner";
import Footer from "./Components/Footer/Footer";
import LightC from "./Components/Light copy/LightC";

import Marquee from "react-double-marquee";

const App = (props) => {
  return (
    <div className="App">
      <img className="bgif" src={bgif} alt="gif" />
      {/* <div
        style={{
          color: "white",
          whiteSpace: "nowrap",
          borderTop: "2px solid white",
          borderBottom: "2px solid white",

          maxWidth: "100%",
          overflow: "hidden",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Marquee
          delay={0}
          speed={0.05}
          childMargin={0}
          direction="right"
          children="XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS 
          XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS "
        />
      </div> */}
      <div className="mp4">
        <a className="App-Mint" href="https://mint.xenexis.io">
          MINT NFT
        </a>
        <h1>
          XENEXIS IS A COLLABORATIVE PREMIUM
          <br />
          MEMBERSHIP NFT PROJECT LED BY AN
          <br />
          EXCLUSIVE GROUP OF WORLDWIDE CRYPTO OGs.
          <br />
          WE DO NOT JUST THINK, WE TAKE NECESSARY
          <br />
          ACTIONS. WE DO NOT HESITATE AND WE DO NOT
          <br />
          FOLLOW PREDETERMINATION.
        </h1>
      </div>
      <ParticlesBackground />
      <Header />

      {/* <div
        style={{
          color: "white",
          whiteSpace: "nowrap",
          borderTop: "2px solid white",
          borderBottom: "2px solid white",

          maxWidth: "100%",
          overflow: "hidden",
          paddingTop: "10px",
          paddingBottom: "10px",
          marginTop: "100px"
        }}
      >
        <Marquee
          delay={0}
          speed={0.05}
          childMargin={0}
          direction="left"
          children="XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS 
          XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS XENEXIS "
        />
      </div> */}
      <header className="App-sec">
        {/* <Light /> */}
        <LightC />
        <Maestro />
        <XENEXIS />
        {/* <Alpha /> */}
        <Banner />
      </header>
      <Footer />
    </div>
  );
};

export default App;
