import './Footer.css';
import footer from './XENEXIS.png';

const Footer = () => {
    return (
        <div className="Footer">
            <img className="footer-logo" src={footer} alt='png' />
        </div>
    )
}

export default Footer;